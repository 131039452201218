import type { ValidateDTO } from './dto-validator.types';

import Ajv from 'ajv';

export const validateDTO: ValidateDTO = (schema, payload, removeAdditional) => {
  const ajv = new Ajv({ allErrors: true, removeAdditional });
  const validate = ajv.compile(schema);
  const isValid = validate(payload);

  if (!isValid) {
    const errors = ajv.errorsText(validate.errors, { separator: ', ' });

    throw new Error(
      `Invalid request. Query parameter schema does not match. Errors: [${errors}]`,
    );
  }

  return isValid;
};
