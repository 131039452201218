import type { Duration, EpgItems } from '../../@schema';
import type { ViewProps } from '../../components/Page';

import snakecaseKeys from 'snakecase-keys';
import { z } from 'zod';

import { isCrawlerBot } from '../../server/middlewares/force-desktop-for-crawler-bots/force-desktop-for-crawler-bots';
import queryParams from '../../constants/query-params.json';
import COMMON_CONST from '../../constants/commons.json';

export const contentIdSchema = z.object({
  contentId: z.string().regex(/^[a-f0-9]{32}$/),
});

export function formatDuration(time?: Duration['format']): string | null {
  if (!time) {
    return null;
  }

  const { hours, minutes } = time;

  let str = '';

  if (hours) {
    str += `${hours}h `;
  }

  if (minutes) {
    str += `${minutes}min`;
  }

  return str;
}

export type ContentIdType = z.infer<typeof contentIdSchema>;

export namespace Utils {
  export const getPercentage = (
    value: number,
    maxValue: number,
    base = 100,
  ): number => Math.round((value * base) / maxValue);

  export const getArrayValuesFromObject = (
    object: Record<string, string>,
  ): Array<string> =>
    Array.from(new Set(Object.entries(object).map(([, value]) => value)));

  export const get = (
    object: Record<string, any>,
    path: string,
    defaultValue = '',
  ): string => {
    const pathArray = path.split('.');
    const result = pathArray.reduce(
      (acc, key) => (acc ? acc[key] : undefined),
      object,
    );

    return result !== undefined ? String(result) : defaultValue;
  };

  export const transformCamelCaseToSnakeCase = (
    input: Record<string, string>,
  ) => snakecaseKeys(input);

  export const getFilteredSearchParams = (searchParams: URLSearchParams) => {
    const requestedQueryParams = queryParams.map(({ name }) => name);
    const filteredParams: Record<string, string> = {};

    searchParams.forEach((_value, key) => {
      if (!requestedQueryParams.some((findKey) => key.startsWith(findKey))) {
        const value = searchParams.get(key);

        if (value !== null) {
          filteredParams[String(key)] = value;
        }
      }
    });

    return new URLSearchParams(filteredParams).toString();
  };

  export const removeOriginParams = () => {
    if (!window.location.search) {
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);
    const filteredSearch = getFilteredSearchParams(searchParams);

    const separator = filteredSearch.length ? '?' : '';

    window.history.replaceState(
      window.history.state,
      '',
      `${window.location.pathname}${separator}${filteredSearch || ''}`,
    );
  };

  export const validateId = ({ contentId }: ContentIdType) => {
    const id = contentId?.toString().trim();
    const parsedContentId = contentIdSchema.safeParse({ contentId: id });

    return parsedContentId;
  };

  export const formatPillLabel = (label: string, size: string): string => {
    if (size !== COMMON_CONST.VERTICAL_CARD_SMALL_SIZE) {
      return label;
    }

    const lowerCaseLabel = label.toLowerCase();
    const PLURAL_REGEX =
      /(\d+)\s(Episodios?|Episódios?)\s(Disponibles?|Disponíveis?)$/i;
    const SINGULAR_REGEX =
      /^(Episodio|Episódio)\s(\d+)\s(Disponible|Disponível)$/i;
    const NEW_BADGE_REGEX = /\b(nuevo|nueva|novo|nova)\b/i;
    const NEW_BADGE_MAP: Record<string, string> = {
      nuevo: 'NUEVO',
      nueva: 'NUEVA',
      novo: 'NOVO',
      nova: 'NOVA',
    };

    if (PLURAL_REGEX.test(lowerCaseLabel)) {
      return label.replace(PLURAL_REGEX, '$1 $2');
    }

    if (SINGULAR_REGEX.test(lowerCaseLabel)) {
      return label.replace(SINGULAR_REGEX, '$1 $2');
    }

    const newBadgeMatchKey = NEW_BADGE_REGEX.exec(label);

    if (newBadgeMatchKey) {
      const matchedKey = newBadgeMatchKey[0].toLowerCase();

      return NEW_BADGE_MAP[matchedKey] || label;
    }

    return label;
  };

  export const validateAppVersion = ({
    appVersion,
    minVersionApp,
  }: Record<string, string>): boolean => {
    const versionMinorApp = appVersion.split('.')[1] as unknown as number;
    const minVersionMinorApp = minVersionApp.split('.')[1] as unknown as number;

    return versionMinorApp >= minVersionMinorApp;
  };

  export const validateAllowList = (
    allowUsersList: Array<number> | undefined,
    userId: number | undefined,
  ) => allowUsersList && userId && allowUsersList.includes(userId);

  export const isVersionAppValid = (
    currentAppVersion: string | undefined,
    minVersionApp: string | null | undefined,
  ) => {
    if (!window?.MobileWebKit || !currentAppVersion) {
      return false;
    }

    return (
      minVersionApp &&
      validateAppVersion({
        appVersion: currentAppVersion,
        minVersionApp,
      })
    );
  };

  export const shouldShowVCMWhenIsLiveEvent = (
    currentAppVersion: string | undefined,
    minVersionApp: string | null | undefined,
    currentContentId: string,
    liveEventsIdsAllowVCM: Array<string> | undefined,
  ) => {
    if (
      !window?.MobileWebKit ||
      !currentAppVersion ||
      !currentContentId ||
      !liveEventsIdsAllowVCM
    ) {
      return false;
    }

    return (
      minVersionApp &&
      validateAppVersion({
        appVersion: currentAppVersion,
        minVersionApp,
      }) &&
      liveEventsIdsAllowVCM.includes(currentContentId)
    );
  };

  export const validateIfIsAbleToAddExperiment = ({
    currentAppVersion,
    minVersionApp,
    allowUsersList,
    userId,
  }: {
    currentAppVersion: string;
    minVersionApp: string;
    allowUsersList: Array<number>;
    userId?: number;
  }) =>
    window?.MobileWebKit &&
    isVersionAppValid(currentAppVersion, minVersionApp) &&
    !validateAllowList(allowUsersList, userId);

  export const transformKeys = (obj: Record<string, any>, target: string) =>
    Object.entries(obj).reduce(
      (newObjTransformed: typeof obj, [key, value]) => {
        if (key.includes(target)) {
          const newKey = key.replace(target, '').trim();

          newObjTransformed[newKey] = value;
        } else {
          newObjTransformed[key] = value;
        }

        return newObjTransformed;
      },
      {},
    );

  export const filterRestrictionsForCrawlers = (
    userAgent: string,
    data: ViewProps,
  ) => {
    const contextRestrictions = data?.configurations?.contextRestrictions;

    if (isCrawlerBot(userAgent) && contextRestrictions) {
      contextRestrictions.restrictions =
        contextRestrictions.restrictions.filter(
          (restriction) =>
            restriction !==
            COMMON_CONST.RESTRICTIONS_VALUES.CONTENT_ACCESS_MODAL,
        );
    }
  };

  export const getCookieValue = (cookieName: string): string | null => {
    if (typeof document === 'undefined') {
      return null;
    }

    const cookies = document.cookie
      .split('; ')
      .map((cookie) => cookie.split('='))
      .find(([name]) => name === cookieName);

    return cookies ? decodeURIComponent(cookies[1]) : null;
  };
}

/**
 * Determines if a live event has ended based on the current status and end time.
 * @param {boolean} current - Indicates whether the live event is currently active.
 * @param {number} end_time - The end time of the event in seconds since the Unix epoch.
 * @returns {boolean} - Returns `true` if the event is not currently live and has already passed its end time.
 */
export const ifLiveContentFinished = (
  current: boolean,
  end_time: number,
): boolean => {
  const now = new Date().getTime();
  // Gets the current timestamp in seconds since the Unix epoch
  const currentUnixTimestamp = Math.floor(now / 1000);

  return !current && end_time <= currentUnixTimestamp;
};

/**
 * * Converts a Unix timestamp (in seconds) into a formatted date-time string.
 *     Format: "day/abbr_month HH:MMh" (example: "12/ene 14:05h").
 *
 * @param {number} end_time Unix timestamp in seconds.
 * @returns {string} Formatted date-time string.
 */

export const DateAndTime = (end_time: number): string => {
  const date = new Date(end_time * 1000);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const day = date.getDate();
  const month = date.toLocaleString('es-ES', { month: 'short' }).toLowerCase();
  const result = `${day}/${month} ${hours}:${minutes}h`;

  return result;
};

/**
 * Displays episode information in format "T5:E10 | House.
 * Only renders for type 'EPISODE'.
 *
 * @param {EpgItems} props - Content data from EPG schema
 * @returns {string | null} Episode info or null
 */

export const EpisodeDisplay = ({
  type,
  season_number,
  episode_number,
  episode_name,
}: EpgItems): string | null => {
  const isEpisodeContent = type === 'EPISODE';

  const formatEpisodeInfo = () => {
    if (!isEpisodeContent) {
      return null;
    }

    return `T${season_number}:E${episode_number} | ${episode_name}`;
  };

  if (!isEpisodeContent) {
    return null;
  }

  return formatEpisodeInfo();
};

/**
 * Returns a single EPG item that matches either the current program or a specific start time.
 * Prioritizes finding the current program (current === true) before matching by start time.
 *
 * @param {EpgItems} props - Content data from EPG schema
 * @param {string} startTime - Target start time to match against EPG items
 * @returns {EpgItem | undefined} Single matching EPG item or undefined if no match found
 *
 * @example
 * const item = getFilteredEpgItems(data.content.epg_items, "2024-02-10T15:00:00Z");
 * if (item) {
 *   console.log(`Found program: ${item.title}`);
 * }
 */
export const getFilteredEpgItems = (
  epgItems?: Array<EpgItems>,
  startTime?: number,
): EpgItems | undefined => {
  if (!epgItems?.length) {
    return undefined;
  }

  if (startTime) {
    const programByStartTime = epgItems.find(
      (item) => item.start_time === Number(startTime),
    );

    if (programByStartTime) {
      return programByStartTime;
    }
  }

  return epgItems[0];
};

/**
 * Converts seconds into a formatted string of hours and/or minutes.
 * If duration is less than 60 minutes, returns only minutes.
 * For 60 minutes or more, converts to hours and remaining minutes.
 *
 * @param {number | undefined} seconds - The number of seconds to format
 * @returns {string} Formatted string in the format "Xh Ymin" or "Ymin", or empty string if no seconds provided
 *
 * @example
 * const duration = formatSecondsToHoursAndMinutes(5400);  // 90 minutes in seconds
 * console.log(duration); // "1h 30min"
 *
 * const shortDuration = formatSecondsToHoursAndMinutes(2700);  // 45 minutes in seconds
 * console.log(shortDuration); // "45min"
 *
 * const noDuration = formatSecondsToHoursAndMinutes(undefined);
 * console.log(noDuration); // ""
 */
export const formatSecondsToHoursAndMinutes = (
  seconds: number | undefined,
): string => {
  if (!seconds) {
    return '';
  }

  const totalMinutes = Math.floor(seconds / 60);

  if (totalMinutes < 60) {
    return `${totalMinutes} min`;
  }

  const hours = Math.floor(totalMinutes / 60);
  const remainingMinutes = totalMinutes % 60;

  if (remainingMinutes === 0) {
    return `${hours} h`;
  }

  return `${hours} h ${remainingMinutes} min`;
};

/**
 * Checks if an event has ended by comparing its end time with the current epoch
 * @param end_time - Event end timestamp
 * @param currentEpoch - Current timestamp
 * @returns {boolean} - true if the event has ended, false otherwise
 */
export const isEnded = (end_time: number, currentEpoch: number): boolean =>
  end_time <= currentEpoch;

/**
 * Checks if an event is live based on its start and end time
 * @param start_time - Event start timestamp
 * @param end_time - Event end timestamp
 * @param currentEpoch - Current timestamp
 * @returns {boolean} - true if the event is live, false otherwise
 */
export const isCurrentLive = (
  start_time: number,
  end_time: number,
  currentEpoch: number,
): boolean => currentEpoch >= start_time && currentEpoch <= end_time;

/**
 * Gets the current Unix timestamp in seconds
 * @returns {number} Current time in Unix timestamp format (seconds)
 * @description Converts the current time from milliseconds (Date.getTime()) to seconds by dividing by 1000 and rounding down
 * @example
 * const timestamp = nowTime(); // Returns current Unix timestamp, e.g. 1708372800
 */
export const nowTime = (): number => {
  const now = new Date().getTime();

  return Math.floor(now / 1000);
};

/**
 * Extracts the start_time parameter from the current URL
 * @returns {number|null} The start_time as a number, or null if not present
 */
export const getStartTimeFromCurrentUrl = () => {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const startTimeParam = urlParams.get('start_time');

    return startTimeParam ? Number(startTimeParam) : null;
  } catch (error: unknown) {
    return null;
  }
};
